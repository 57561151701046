// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/root/workspace/locamoscms-vn/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';

export function getRoutes() {
  const routes = [
  {
    "path": "/login",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__login' */'@/pages/login')}),
    "exact": true
  },
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layout__MainLayout__index' */'@/layout/MainLayout/index')}),
    "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/layout/Auth/index')})],
    "routes": [
      {
        "path": "/locationNft",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__locationNft' */'@/pages/locationNft')}),
        "exact": true
      },
      {
        "path": "/restrictedLocation",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__restrictedLocation' */'@/pages/restrictedLocation')}),
        "exact": true
      },
      {
        "path": "/locationNft/:location_id",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__locationNft__location_id' */'@/pages/locationNft/[location_id]')}),
        "exact": true
      },
      {
        "path": "/priceManagement",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__priceManagement' */'@/pages/priceManagement')}),
        "exact": true
      },
      {
        "path": "/purchaseOrder",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__purchaseOrder' */'@/pages/purchaseOrder')}),
        "exact": true
      },
      {
        "path": "/purchaseOrder/:order_id",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__purchaseOrder__order_id' */'@/pages/purchaseOrder/[order_id]')}),
        "exact": true
      },
      {
        "path": "/accountManagement",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__accountManagement' */'@/pages/accountManagement')}),
        "exact": true
      },
      {
        "path": "/accountManagement/:admin_id",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__accountManagement__admin_id' */'@/pages/accountManagement/[admin_id]')}),
        "exact": true
      },
      {
        "path": "/authManagement",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__authManagement' */'@/pages/authManagement')}),
        "exact": true
      },
      {
        "path": "/authManagement/:author_id",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__authManagement__author_id' */'@/pages/authManagement/[author_id]')}),
        "exact": true
      },
      {
        "path": "/clientsManagement",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__clientsManagement' */'@/pages/clientsManagement')}),
        "exact": true
      },
      {
        "path": "/clientsManagement/:user_id",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__clientsManagement__user_id' */'@/pages/clientsManagement/[user_id]')}),
        "exact": true
      },
      {
        "path": "/notiManagement",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__notificationManagement' */'@/pages/notificationManagement')}),
        "exact": true
      },
      {
        "path": "/notiManagement/:noti_id",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__notificationManagement__noti_id' */'@/pages/notificationManagement/[noti_id]')}),
        "exact": true
      },
      {
        "path": "/kycManagement",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__kycManagement' */'@/pages/kycManagement')}),
        "exact": true
      },
      {
        "path": "/systemConfig",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__systemConfiguration' */'@/pages/systemConfiguration')}),
        "exact": true
      },
      {
        "path": "/managementTransfer",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__managementTransfer' */'@/pages/managementTransfer')}),
        "title": "Quản lý giao dịch tiền và Loca",
        "exact": true
      },
      {
        "path": "/customWallet",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__customWallet' */'@/pages/customWallet')}),
        "exact": true
      },
      {
        "path": "/setLevelLocaBonus",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__setLevelLocaBonus' */'@/pages/setLevelLocaBonus')}),
        "exact": true
      },
      {
        "path": "/rosesManagement",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__rosesManagement' */'@/pages/rosesManagement')}),
        "exact": true
      },
      {
        "path": "/rosesManagement/vice-director",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__rosesManagement' */'@/pages/rosesManagement')}),
        "exact": true
      },
      {
        "path": "/rosesManagement/general-manager",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__rosesManagement' */'@/pages/rosesManagement')}),
        "exact": true
      },
      {
        "path": "/rosesManagement/receive-locabonus",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__rosesManagement' */'@/pages/rosesManagement')}),
        "exact": true
      },
      {
        "path": "/rosesManagement/:rose_id",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__rosesManagement__rose_id' */'@/pages/rosesManagement/[rose_id]')}),
        "exact": true
      },
      {
        "path": "/statisticsReport",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__statisticsReport' */'@/pages/statisticsReport')}),
        "exact": true
      },
      {
        "path": "/voucherManagement",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__voucherManagement' */'@/pages/voucherManagement')}),
        "exact": true
      },
      {
        "path": "/newsManagement",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__newsManagement' */'@/pages/newsManagement')}),
        "exact": true
      },
      {
        "path": "/newsManagement/:post_id",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__newsManagement__post_id' */'@/pages/newsManagement/[post_id]')}),
        "exact": true
      },
      {
        "path": "/requestTransferLocaVoucher",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__requestTransferLocaVoucher' */'@/pages/requestTransferLocaVoucher')}),
        "exact": true
      },
      {
        "path": "/voucherManagement",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__voucherManagement' */'@/pages/voucherManagement')}),
        "exact": true
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
